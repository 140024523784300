import React from 'react'
import { Col } from 'components/grid'
import { Button } from '@arckit/ui'
import { Event } from 'pages/events/webinars'
import { format } from 'date-fns'

interface Props {
	event: Event
	previous?: boolean
	setIsModalOpen?: (bool: boolean) => void
	setRecording?: (title: string) => void
}

export default function WebinarCard({ event, previous, setIsModalOpen, setRecording }: Props) {
	function handleViewRecordingClick() {
		// @ts-ignore
		setIsModalOpen(true)
		// @ts-ignore
		setRecording(event.recording ? event.recording.title : '')
	}

	return (
		<Col className="mb-6">
			<div className="border bg-white p-6 text-center shadow">
				<h3 className="mb-2">{event.title}</h3>
				<p className="text-xl text-slate-400">{format(new Date(event.date), 'MMMM do, yyyy hh:mm a')}</p>
				<div className="mt-10 flex flex-wrap justify-center">
					{event.presenters.map((presenter) => (
						<div className="w-full md:w-1/3">
							<img src={presenter.photo.file.url} alt={presenter.name} className="mx-auto h-44 w-44 rounded-full object-cover object-top" />
							<p className="mt-4 mb-0 font-medium text-slate-600">{presenter.name}</p>
							<p className="text-sm italic">{presenter.title}</p>
						</div>
					))}
				</div>
				<p className="mt-6 text-left">{event?.description?.description}</p>

				{!previous && (
					<div className="mt-6">
						<a href={event?.registrationLink} target="_blank">
							<Button label="Register here" fullWidth size="lg" />
						</a>
					</div>
				)}

				{previous && event.recording && (
					<div className="mt-6">
						<Button label="View Recording" onClick={handleViewRecordingClick} fullWidth size="lg" />
					</div>
				)}
			</div>
		</Col>
	)
}
